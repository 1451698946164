import React, { Fragment, useEffect, useState } from "react";
import { IconEnrollDropdownArrow } from "../../icons";
import NewUser from "./newUser";
import { ShashiCashTC, Swal } from "../../components";
import { enrollSelector, setCollapseEnroll, setStatus } from "./enrollSlice";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "@mui/material";
import { signInSelector } from "../signIn/signInSlice";
import { costSummarySelector } from "../costSummary/costSummarySlice";
import ExistingUserEnroll from "./existingUserEnroll";
import { ThreeDots } from "react-loader-spinner";
import { roomSelector } from "../rooms/roomSlice";

const DropDownBtn = ({ onClick, collapseEnroll }) => {
  return (
    <div
      onClick={onClick}
      className={`text-magenta w-fit cursor-pointer filter-brightness-150 font-light text-[12px] duration-1000 mt-[13px] ${
        collapseEnroll ? `mb-[5px]` : `mb-[15px]`
      } leading-[18px] flex flex-nowrap gap-[3px]`}
    >
      Enroll Now
      <IconEnrollDropdownArrow collapse={collapseEnroll} />
    </div>
  );
};

function AvailableCashBack({ amount }) {
  return (
    <div className="flex justify-between w-full items-center mt-[15px]">
      <label className="text-[14px] leading-[22px] ">
        Cash Back Earned on this Reservation
      </label>
      <label className="text-[16px] leading-[19.2px]">
        {Number(amount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </label>
    </div>
  );
}

const Loader = () => (
  <div className="flex justify-center items-center w-full h-[40px] mt-[12px]  mb-[12px]">
    <ThreeDots
      height="10"
      width="28"
      radius="9"
      color="black"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>
);

function EnrollV2() {
  const { status, isLoading, collapseEnroll } = useSelector(enrollSelector);
  const { userData } = useSelector(signInSelector);
  const { packagesAndRoomTotalCost } = useSelector(costSummarySelector);
  const { selectedRatePlan } = useSelector(roomSelector)
  const dispatch = useDispatch();

  // CONVER TO LOWER_CASE  
 let converAndCompare = (arg) => {
    if (arg?.toLowerCase() === selectedRatePlan?.pmsRateExternalCode?.toLowerCase()) {
      return true
    } else {
      return false
    }
  }

  let comparePmsRateExternaCode = () => {
    if (
      converAndCompare("CYBERM") ||
      converAndCompare("CYBER") ||
      converAndCompare("blackfriday") ||
      converAndCompare("Cyber2023")
      ) {
      return 20;
    } else {
      return 5;
    }
  };

  return (
    <Fragment>
      <Swal dispatchMetod={setStatus} status={status} />
      <ShashiCashTC />
      <div className="h-[28px]" />
      <section className="bg-white lg:bg-[#F8F8F8] pt-[26px] -mx-[16px] shahsiCashCard-base">
        <div className=" px-[16px] ">
          <h4 className="text-magenta filter-brightness-150 text-[20px] font-normal leading-[22px]">
            {/* EARN 5-{comparePmsRateExternaCode()}% CASH BACK */}
            EARN 5-10% CASH BACK
          </h4>
          <div className="text-magenta filter-brightness-150  font-light text-[10px] mt-[9px] leading-[18px]">
            ENROLL IN SHASHI CASH LOYALTY PROGRAM
          </div>
          <div className="font-light text-[12px] leading-[18px] mt-[6px]">
            {comparePmsRateExternaCode() == 20 && (
              <>
                Earn 20% Cash Back on Reservations for promotional offers.
                <br />
              </>
            )}
            Earn 5% Cash Back on Rooms <br />
            Earn 10% Cash Back on Rooms after 10 Qualified Room Nights.<br />
            Transfer Cash to your Bank, PayPal or Venmo.
          </div>
          <AvailableCashBack
            amount={((packagesAndRoomTotalCost || 0) / 100) *
            comparePmsRateExternaCode()}
          />

          {userData?.email ? (
            <>
              {isLoading? (
                <Loader />
              ) : (
                <>
                  <ExistingUserEnroll
                    isEnrollUiVersion2={true}
                    userData={userData}
                  />
                  <div className="h-[12px]" />
                </>
              )}
            </>
          ) : (
            <>
              <DropDownBtn
                collapseEnroll={collapseEnroll}
                onClick={() => dispatch(setCollapseEnroll(!collapseEnroll))}
              />
              <Collapse timeout={1600} in={collapseEnroll}>
                <NewUser isEnrollUiVersion2={true} />
              </Collapse>
            </>
          )}
        </div>
      </section>
    </Fragment>
  );
}

export default EnrollV2;
